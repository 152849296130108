













import {
  computed,
  defineComponent,
  inject,
  PropType,
  ref
} from '@nuxtjs/composition-api';
import type {Aggregation} from '~/modules/GraphQL/types';
import RangeSlider from '~/components/Palmers/BaseComponents/Slider/RangeSlider.vue';

export default defineComponent({
  components: {
    RangeSlider
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
    selectedRange: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const selectedRange = ref(props.selectedRange);

    const firstOptionPrice = props.filter.options[0].value.split('_');
    const lastOptionPrice = props.filter.options[props.filter.options.length - 1].value.split('_');

    const minValue = ref(parseInt(firstOptionPrice[0]));
    const maxValue = ref(parseInt(lastOptionPrice[1]));
    const step = ref(5);

    const min = ref(minValue.value);
    const max = ref(maxValue.value);


    if (selectedRange.value.length) {
      //@ts-ignore
      const firstOptionSelectedPrice = selectedRange.value[0].split('_');
      //@ts-ignore
      const lastOptionSelectedPrice = selectedRange.value[selectedRange.value.length - 1].split('_');

      minValue.value = parseInt(firstOptionSelectedPrice[0]);
      maxValue.value = parseInt(lastOptionSelectedPrice[1]);
    }

    return {
      selected: '',
      minValue,
      maxValue,
      min,
      max,
      step
    };
  },
  methods: {
    selectDragging(values) {
      this.$emit('selectFilter', {
        value: `${values.minValue}_${values.maxValue}`,
        label: `${values.minValue}-${values.maxValue}`,
        __typename: "AggregationOption"
      });
    }
  }
});
