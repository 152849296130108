

























































import {defineComponent} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RangeSlider',
  props: {
    trackHeight: {
      type: Number,
      default() {
        return 1
      }
    },
    min: {
      type: Number,
      require: true
    },
    max: {
      type: Number,
      require: true
    },
    step: {
      type: Number,
      require: true
    },
    minValueRange: {
      type: Number,
      require: true
    },
    maxValueRange: {
      type: Number,
      require: true
    },
    showInputs: {
      type: Boolean,
      default() {
        return false
      }
    },
    selectDragging: {
      type: Function,
      require: true
    },
    hideMaxButton: {
      type: Boolean,
      default() {
        return false
      }
    },
    showValuesAbove: {
      type: [Boolean, String],
      default() {
        return false
      }
    },
  },
  watch: {
    isDragging(newVal, oldVal) {
      if(oldVal === true && newVal === false) {
        this.emitDraggingEvent();
      }
    }
  },
  data() {
    return {
      totalSteps: 0,
      percentPerStep: 1,
      trackWidth: null,
      isDragging: false,
      updateInputs: 0,
      pos: {
        curTrack: null
      },
      maxValue: Number(this.maxValueRange),
      minValue: Number(this.minValueRange)
    }
  },
  methods: {
    customInput($event) {
      const isClicked = $event?.data === undefined;

      if(isClicked) {
        this.updateInputs += 1;
      }
    },
    selectInput($ev) {
      const val = parseInt($ev.target.value);

      if ($ev.target.classList[0] === 'max') {
        this.maxValue = val;
      } else {
        this.minValue = val;
      }

      this.maxValue = this.maxValue > this.max ? this.max : this.maxValue;
      this.minValue = this.minValue > this.max || this.minValue <= this.min ? this.min : this.minValue;

      this.maxValue = !this.maxValue ? this.max : this.maxValue;
      this.minValue = !this.minValue ? this.min : this.minValue;

      this.maxValue = this.maxValue < this.minValue ? this.minValue + this.step : this.maxValue;

      if(document.querySelector('.track1')?.['style']) {
        //@ts-ignore
        document.querySelector('.track1').style.left = this.valueToPercent(this.minValue) + '%'
      }

      if(!this.hideMaxButton && document.querySelector('.track2')?.['style']) {
        //@ts-ignore
        document.querySelector('.track2').style.left = this.valueToPercent(this.maxValue) + '%'
      }

      this.setTrackHightlight();

      this.$emit('selectDragging', {
        minValue: this.minValue,
        maxValue: this.maxValue
      })
    },
    moveTrack(track, ev) {
      let percentInPx = this.getPercentInPx();

      //@ts-ignore
      let trackX = Math.round(this.$refs._vpcTrack.getBoundingClientRect().left);
      let clientX = ev.clientX;
      let moveDiff = clientX - trackX;

      let moveInPct = moveDiff / percentInPx

      if (moveInPct < 0 || moveInPct > 100) return;

      let value = (Math.round(moveInPct / this.percentPerStep) * this.step) + this.min;
      value = Math.round(value / this.step) * this.step;

      if (track === 'track1') {
        if (value >= (this.maxValue - this.step)) return;
        this.minValue = Math.max(this.min, value);
      }

      if (track === 'track2' && !this.hideMaxButton) {
        if (value <= (this.minValue + this.step)) return;
        this.maxValue = Math.min(this.max, value);
      }

      //@ts-ignore
      this.$refs[track].style.left = moveInPct + '%';
      this.setTrackHightlight()
    },
    mousedown(ev, track) {
      if (this.isDragging) return;
      this.isDragging = true;
      this.pos.curTrack = track;
    },

    touchstart(ev, track) {
      this.mousedown(ev, track)
    },

    mouseup(ev, track) {
      if (!this.isDragging) return;
      this.isDragging = false;
    },

    emitDraggingEvent() {
      this.$emit('selectDragging', {
        minValue: this.minValue,
        maxValue: this.maxValue,
      });
    },

    touchend(ev, track) {
      this.mouseup(ev, track)
    },

    mousemove(ev, track) {
      if (!this.isDragging) return;
      this.moveTrack(track, ev)
    },

    touchmove(ev, track) {
      this.mousemove(ev.changedTouches[0], track)
    },

    valueToPercent(value) {
      return ((value - this.min) / this.step) * this.percentPerStep
    },

    setTrackHightlight() {
      if(!this.$refs.trackHighlight?.['style']) return;
      //@ts-ignore
      this.$refs.trackHighlight.style.left = this.valueToPercent(this.minValue) + '%'
      //@ts-ignore
      this.$refs.trackHighlight.style.width = (this.valueToPercent(this.maxValue) - this.valueToPercent(this.minValue)) + '%'
    },

    getPercentInPx() {
      //@ts-ignore
      let trackWidth = this.$refs._vpcTrack.offsetWidth;
      let oneStepInPx = trackWidth / this.totalSteps;
      // 1 percent in px
      return oneStepInPx / this.percentPerStep;
    },

    setClickMove(ev) {
      if(this.hideMaxButton) {
        //@ts-ignore
        let track1Left = this.$refs.track1.getBoundingClientRect().left;
        if (ev.clientX < track1Left) {
          this.moveTrack('track1', ev)
        }
      }else {
        //@ts-ignore
        let track1Left = this.$refs.track1.getBoundingClientRect().left;
        //@ts-ignore
        let track2Left = this.$refs.track2.getBoundingClientRect().left;
        // console.log('track1Left', track1Left)
        if (ev.clientX < track1Left) {
          this.moveTrack('track1', ev)
        } else if ((ev.clientX - track1Left) < (track2Left - ev.clientX)) {
          this.moveTrack('track1', ev)
        } else {
          this.moveTrack('track2', ev)
        }
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      // calc per step value
      this.totalSteps = (this.max - this.min) / this.step;

      // percent the track button to be moved on each step
      this.percentPerStep = 100 / this.totalSteps;
      // console.log('percentPerStep', this.percentPerStep)

      if(document.querySelector('.track1')?.['style']) {
        // set track1 initial
        //@ts-ignore
        document.querySelector('.track1').style.left = this.valueToPercent(this.minValue) + '%'
        // track2 initial position
      }

      if(!this.hideMaxButton && document.querySelector('.track2')?.['style']) {
        //@ts-ignore
        document.querySelector('.track2').style.left = this.valueToPercent(this.maxValue) + '%'
      }
      // set initial track highlight
      this.setTrackHightlight()

      const self = this;

      ['mouseup', 'mousemove'].forEach(type => {
        document.body.addEventListener(type, (ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          if (self.isDragging && self.pos.curTrack) {
            //@ts-ignore
            self[type](ev, self.pos.curTrack)
          }
        })
      });
    })
  }
});
